import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Form from './Form';
import Results from './Results';
import moment from 'moment';

const API_BASE_URL = process.env.REACT_APP_BASE_URI;

const OnlineTests = () => {
  const [onlineTests, setOnlineTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchOnlineTests();
  }, []);

  const fetchOnlineTests = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(`${API_BASE_URL}/OnlineTest`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOnlineTests(res.data);
    } catch (error) {
      console.error('Error fetching online tests:', error);
      setError('Failed to fetch online tests. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const deleteOnlineTest = async (id: any) => {
    try {
      await axios.delete(`${API_BASE_URL}/OnlineTest/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast.success('Online test deleted successfully');
      fetchOnlineTests();
    } catch (error) {
      console.error('Error deleting online test:', error);
      toast.error('Failed to delete online test');
    }
  };

  const handleCopy = (id: any) => {
    const origin = window.location.origin;
    let prefix = 'https://visaclient.vercel.app';

    if (origin.includes('visaadmin')) {
      navigator.clipboard.writeText(`${prefix}/online-test/${id}`);
      toast.success('Link copied to clipboard');
      return;
    }

    if (origin.includes('admin')) {
      const domain = origin.split('.')[1];
      prefix = `https://portal.${domain}.com`;
    }
    navigator.clipboard.writeText(`${prefix}/online-test/${id}`);
    toast.success('Link copied to clipboard');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Online Tests</h1>
          <button
            onClick={() => setIsFormOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create New Test
          </button>
        </div>

        {loading && (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
          </div>
        )}

        {error && (
          <div
            className="bg-red-50 border-l-4 border-red-400 p-4 mb-8"
            role="alert"
          >
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}

        {!loading && onlineTests.length === 0 && (
          <div className="text-center py-12">
            <p className="text-xl text-gray-600">No Online Tests found</p>
            <p className="mt-2 text-sm text-gray-500">
              Get started by creating a new test.
            </p>
          </div>
        )}

        {!loading && onlineTests.length > 0 && (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {onlineTests.map((onlineTest: any) => (
              <div
                key={onlineTest._id}
                className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
              >
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg font-medium text-gray-900 truncate">
                    {onlineTest.name}
                  </h3>
                  <dl className="mt-2 space-y-1">
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">
                        Client:
                      </dt>
                      <dd className="text-sm text-gray-900">
                        {onlineTest.clientId.name}
                      </dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">
                        Case ID:
                      </dt>
                      <dd className="text-sm text-gray-900">
                        {onlineTest.caseId}
                      </dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">
                        Expiry:
                      </dt>
                      <dd className="text-sm text-gray-900 mt-2">
                        {/* Show the data in month day, year format */}
                        {onlineTest.expiryDate
                          ? moment(onlineTest.expiryDate).format('MMM DD, YYYY') // Format if expiry exists
                          : 'No Expiry Date'}{' '}
                        {/* Fallback if expiry is undefined */}
                      </dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">
                        Status:
                      </dt>
                      <dd
                        className={`text-sm font-medium ${
                          onlineTest.isSubmitted
                            ? 'text-green-600'
                            : 'text-yellow-600'
                        }`}
                      >
                        {onlineTest.isSubmitted ? 'Submitted' : 'Not Submitted'}
                      </dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">
                        Questions:
                      </dt>
                      <dd className="text-sm text-gray-900">
                        {onlineTest.questions.length}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="px-4 py-4 sm:px-6 flex justify-between items-center">
                  {onlineTest.isSubmitted ? (
                    <Results onlineTest={onlineTest} />
                  ) : (
                    <button
                      onClick={() => {
                        handleCopy(onlineTest._id);
                      }}
                      className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Copy Link
                    </button>
                  )}
                  <button
                    onClick={() => deleteOnlineTest(onlineTest._id)}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {isFormOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setIsFormOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start mt-10">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Create New Online Test
                  </h3>
                  <div className="mt-2">
                    <Form
                      fetchOnlineTests={fetchOnlineTests}
                      onClose={() => setIsFormOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnlineTests;
